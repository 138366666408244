.ag-theme-quartz,
.ag-theme-quartz-dark {
  --ag-row-height: 60px;
  --ag-active-color: #748df0;
}

.ag-theme-quartz-dark {
  --ag-background-color: #171923;
  --ag-header-background-color: #2d3748;
}

.ag-theme-quartz {
  --ag-background-color: white;
  --ag-header-background-color: #edf2f7;
}

.cell-filter {
  background-color: var(--ag-header-background-color);
}

.json-to-table table {
  table-layout: fixed;
}

.json-to-table tr:nth-child(even) {
  background-color: transparent !important;
}
