.fhir-container__ResourceContainer__card-body {
  padding: 0;
}

/* DATA GRID */
.fill-grid {
  background: none;
  height: auto;
}

.filter-class {
  inline-size: 100%;
  padding: 4px;
  font-size: 14px;
  border: 1px solid #4e5766;
  background: none;
  border-radius: 5px;
}

.rdg-cell {
  background: none;
  outline: none;
}

.filter-cell {
  line-height: 35px;
  padding: 0;
  background: none;

  > div {
    padding-block: 0;
    padding-inline: 8px;
  }
}

.add-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.add-scroll::-webkit-scrollbar:vertical {
  width: 9px;
}

.add-scroll::-webkit-scrollbar:horizontal {
  height: 9px;
}

.add-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #a7a7a7;
}
